@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

body {
  margin: 0;
  background-color: white !important;
  font-family: 'Inter', sans-serif;
  /* background:
    radial-gradient(circle, #d1d1d1 10%, transparent 10%) center/24px 24px,
    radial-gradient(circle, #d1d1d1 10%, transparent 10%) center/24px 24px !important;
  background-repeat: repeat !important; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
